.player-wrapper {
    /*position: relative;*/
    margin-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
    background: #000;
    margin-right: 15%;
    margin-left: 15%;
    border-radius: 25px;
}

.react-player {
    /*position: absolute;*/
    top: 0;
    left: 0;
    margin: auto;
    display: block;
}

.video--content{
    padding-top: 20rem;
}

.react-player video{margin-bottom: -6px}


@media only screen and (max-width: 600px) {
    .video--content{
        padding-top: 0;
    }
    .player-wrapper{
        margin-top: 5rem;
    }
}