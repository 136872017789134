.navbar {
    width: 100%;
    height: 75px;
    display: flow-root;
    justify-content: space-between;
    align-items: center;
    /*padding: 30px 10%;*/
    z-index: 999999;
    position: fixed;
}

.active .nav-links{
    color: #333;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    max-width: 100%;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    position: relative;
    top: 25px;
    width: 90px;
}

.fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
}

.nav-menu {
    display: flow-root;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 100%;
    justify-content: end;
}

.nav-item {
    height: 40px;
    display: inline-flex;
    padding: 0 2%;
    margin: 0 2%;
}

.nav-links {
    position: relative;
    color: #787878;
    display: flex;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    height: 100%;
    top: 1.9rem;
}

.nav-links:hover,.nav-links:focus  {
    transition: 0.9s;
    color: #00784b;
}

.nav-links:hover:after{
    transform: translateX(-50%) scaleX(1);
    -webkit-transform:translateX(-50%) scaleX(1);
    -webkit-transform-origin: 50% 50%;
}

.nav-links:after{
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) scaleX(0);
    -webkit-transform:translateX(-50%) scaleX(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    width: 100%;
    height: 2px;
    background-color: #D0AB3C;
    transition: all 250ms;
    -webkit-transition: all 250ms;

}

.fa-bars {
    color: #000;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.nav-menu-btn{
    position: absolute;
    left: 0;
    top: 1.6rem;
    padding-left: 15px;
}

.btnMobile{
    margin-left: 0.55rem;
}

.active--color .nav-links{
    color: #333;
}

@media screen and (max-width: 960px) {
    .nav-logo{
      display: none;
    }
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        right: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        right: auto;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover{
        background-color: #fff;
        color: #242424;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
    }
}