.react-tabs {
    -webkit-tap-highlight-color: transparent;
    padding-top: 10px;

}

.react-tabs__tab-list {
    border-bottom: 0;
    text-align: center;
    font-weight: 500;
    margin: 0;
    position: relative;
    z-index: 1;
    display: inherit;
}

.react-tabs__tab-list:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    bottom: 0px;
    left: 25%;
    right: 25%;
    transition: 0.3s ease;
    border-bottom: 2px solid #CFA936;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    position: initial !important;
    width: 15%;
    text-transform: uppercase;
}

.react-tabs__tab--selected {
    display: inline-block;
    background: #ffff;
    border-bottom: 4px solid #00552B;
}


.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}

@media only screen and (max-width: 600px) {
    .react-tabs__tab {
        width: 45%;
    }

    .react-tabs__tab-list:before {
        left: 0;
        right: 0;
    }
}
