.box--shop {
    margin-top: 5rem;
    padding-top: 6rem;
}

.item-image img {
    width: 355px;
    height: 485px;

    --s: 15px;  /* size of the frame */
    /*--b: 2px;   !* border thickness *!*/
    /*--w: 200px; !* width of the image *!*/
    /*--c: #046742;*/

    /*width: var(--w);*/
    /*aspect-ratio: 1;*/
    /*object-fit: cover;*/
    /*padding: calc(2*var(--s));*/
    /*--_g: var(--c) var(--b),#0000 0 calc(100% - var(--b)),var(--c) 0;*/
    /*background:*/
    /*        linear-gradient(      var(--_g)) 50%/100% var(--_i,100%) no-repeat,*/
    /*        linear-gradient(90deg,var(--_g)) 50%/var(--_i,100%) 100% no-repeat;*/
    /*outline: calc(var(--w)/2) solid #0009;*/
    /*outline-offset: calc(var(--w)/-2 - 2*var(--s));*/
    transition: .6s;
    /*cursor: pointer;*/
}

.item-image img:hover{
    /*outline: var(--b) solid #dfaf43;*/
    /*outline-offset: calc(var(--s)/-2);*/
    /*--_i: calc(100% - 2*var(--s));*/
    padding: calc(2*var(--s));
    /*--c: #046742;*/
    /*border-radius: 17px;*/
    opacity: 0.7;
}

.box--shop .item-content-box .item-image{
    overflow: hidden;
    margin: 1px;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    min-height: 270px;
    text-align: center;
    border-radius: 12px;
}

.box--shop .box--shop--items{
    margin-bottom: 3rem;
    padding-bottom: 3rem;
}

.box--shop--items .item-content-box{
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

.product-title{
    display: inline-block;
    margin-bottom: 5px;
    padding-bottom: 2px;
    font-size: 13px;
    font-weight: 500;
}

.product-item--color{
    display: none;
    justify-content: flex-end;
    top: -15px;
    flex-grow: 1;
    position: relative;
}

.item--color{
    position: relative;
    min-width: 7px;
    min-height: 7px;
    width: 0.5vw;
    height: 0.5vw;
    border-radius: 50%;
    margin-right: 13px;
    background-color: #000;
    cursor: pointer;
}

.item--color.active{
    cursor: auto;
}

.item--color.active:before{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 14px;
    min-height: 14px;
    width: 1vw;
    height: 1vw;
    border-radius: 50%;
    border: 1px solid #000;
    transition: .3s;
}


@media only screen and (min-width: 1400px) {
    .item-content-box .item-image img{
        width: 100%;
        height: 100%;
    }
    .item-image img {aspect-ratio: 0;}
}