.collection-box {
    position: relative;
}

.collection-item1 {
    position: absolute;
    background: #d4b24c78;
    width: 660px;
    height: 798px;
    right: 0;
    /* z-index: -1; */
    top: 3rem;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.collection-content img:hover {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -webkit-transition: all 1s ease;

    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    transition: 1s ease;
}

.collection-item2 {
    position: absolute;
    background: #d4b24c78;
    width: 770px;
    height: 800px;
    left: 0;
    z-index: 1;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.image1 {
    width: 400px;
    height: 500px;
    position: absolute;
    right: 90px;
    top: 140px;
    border-radius: 10px;
}

.image2 {
    width: 545px;
    height: auto;
    left: 94px;
    position: absolute;
    top: 150px;
    border-radius: 10px;
}

.collection-category {
    margin-top: 12rem;
}

.collection-cate-box .collection-winter {
    background: #ECDCAE;
    border-radius: 180px 144px 144px 180px;
}

.collection-cate-box .collection-spring {
    background: #8EBBAA;
    border-radius: 144px 180px 180px 144px;
}

.collection-winter .collection-winter-img img, .collection-spring .collection-spring-img img {
    border-radius: 210px;
    width: 100%;
}

.collection-winter .collection-winter-text h5, .collection-spring .collection-spring-text h5 {
    text-align: center;
    position: relative;
    top: 4rem;
    text-transform: uppercase;
    font-weight: lighter;
    font-family: sans-serif;
    font-size: 1.2rem;
    line-height: 33px;
    color: #00432A;
    left: 31px;
    right: auto;
}

.collection-spring-t {
    margin-top: 4rem;
}

.collection-spring .collection-spring-text h5 {
    right: 31px;
    left: auto;
}

.collection-link {
    text-align: center;
}

.collection-link a {
    position: relative;
    top: 8rem;
    right: auto;
    left: 31px;
    text-transform: uppercase;
    text-decoration: none;
    color: #00432A;
    font-family: sans-serif;
}

.collection-spring .collection-link a {
    left: auto;
    right: 31px;
}

.collection-category-content {
    margin-top: 9rem;
}

.collection--video .video--content {
    padding-top: 5rem;
}

.collection--video .video--content .player-wrapper {
    margin-top: 0;
}

.collection--statement--box {
    margin-top: 4rem;
    text-align: justify;
    color: #00552B;
}

.collection--statement--box p {
    font-family: sans-serif;
}

.statement--second--box {
    margin-top: 0;
}

/*.collection--statement--mood .slick-slider{*/
/*    margin-top: 4.3rem;*/
/*}*/

.collection__tab .collection--inner--cate {
    margin-top: 4rem;
}

.collection--inner--cate .collection--images--box img {
    border-radius: 75px;
    /*width: 100%;*/
    /*--s: 15px;*/
    transition: .6s;
}

.collection--inner--cate .collection--images--box {
    position: relative;
    height: 550px;
}

.collection--images--box .collection--images--name {
    position: relative;
    bottom: 65px;
    text-align: center;
    background: #ffffff94;
    height: 65px;
    border-radius: 75px 75px 0 0;
    display: none;
    transition: .6s;
}

.collection--images--box .collection--images--name h5 {
    position: relative;
    top: 15px;
    font-family: sans-serif;
    color: #00552B;
}

.collection--images--box:hover img {
    /*padding: calc(2*var(--s));*/
    border-radius: 0;
}

.collection--images--box:hover .collection--images--name {
    display: block;
}

.collection--statement--mood .mood--board--title {
    text-align: center;
    margin-top: 2.3rem;
    font-size: 1rem;
    color: #046742;
}

.spring--inner--slider .slick-prev:before, .spring--inner--slider .slick-next:before {
    color: #cdcdcd;
}

.collection-spring-b{
    margin-bottom: 4rem;
}

.old-spring{
    border-radius: 180px 144px 144px 180px !important;
}

.old-winter{
    border-radius: 144px 180px 180px 144px !important;
}

@media only screen and (min-width: 1450px) {
    .collection-item1 {
        width: 800px;
    }

    .collection-item2 {
        width: 940px
    }

    .collection--inner--cate .collection--images--box {
        height: 650px;
    }
}

@media only screen and (max-width: 600px) {
    .collection-item1, .collection-item2 {
        position: relative;
        width: 100%;
        border-radius: 20px;
    }

    .collection-item1 {
        margin-bottom: 5rem;
    }

    .collection-item1 .image1, .collection-item2 .image2 {
        right: 0;
        left: 0;
    }

    .collection-cate-box .collection-winter, .collection-cate-box .collection-spring {
        border-radius: 180px;
    }

    .collection-winter .collection-winter-text h5, .collection-spring .collection-spring-text h5 {
        position: initial;
    }

    .collection-winter-text, .collection-spring-text {
        margin: 5rem 0;
    }

    .collection-link a {
        top: -60px;
        right: 0;
        left: 0;
    }

    .collection-spring .collection-link a{
        right: 0;
    }

}