* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'IRANSansWeb(FaNum)', 'PT Sans', sans-serif;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

@font-face {
  font-family: "IRANSansWeb(FaNum)";
  src: local("IRANSansWeb(FaNum)"),
  url("./fonts/IRANSans/ttf/IRANSansWeb(FaNum).ttf") format("truetype"),
  url('./fonts/IRANSans/eot/IRANSansWeb(FaNum).eot') format('eot'),
  url('./fonts/IRANSans/woff/IRANSansWeb(FaNum).woff') format('woff'),
  url('./fonts/IRANSans/woff2/IRANSansWeb(FaNum).woff2') format('woff2');
}

body {
  direction: ltr;
}

::-webkit-scrollbar {
  height: 5px;
  width: 6px;
  background: gray;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #888;
  border-radius: 10px;
}

