#footer--box {
    padding: 0 !important;
}

.sarv--footer {
    height: 95px;
    background: #EBDBAB;
    margin-top: 8rem;
    display: flex;
    justify-content: center;
}

.footer--menu {
    display: flow-root;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 100%;
    justify-content: end;
    padding-left: 0;
}

.footer--item {
    height: 40px;
    display: inline-flex;
}

.footer-links {
    position: relative;
    color: #888;
    display: flex;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    font-size: 40px;
    top: 2rem;
}

.footer--copyright {
    background: #E0C87E;
    text-align: center;
}

.footer--copyright p {
    margin-top: 9px;
    color: #888;
    font-size: 14px;
}

.footer--item .footer-links:hover {
    transition: 0.9s;
    color: #006841;
}
