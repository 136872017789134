.biography--content--box {
    margin-top: 3rem;
}

.bio__tab{
    margin-top: 10rem;
}

.biography--content--box .intro--box h3, .biography--content--box p {
    font-family: sans-serif;
    color: #046742;
}

.biography--content--box p{
    text-align: justify;
}

.bio--content .bio--title h3 {
    position: relative;
    text-transform: uppercase;
    font-weight: bold;
    color: #046742;
}

.bio--content .bio--title h3:before {
    content: "";
    position: absolute;
    border-bottom: 3px solid #e1bf67;
    width: 26%;
    top: 34px;
    text-align: center;
    left: 37%;
    right: 37%;
}

.biography--content--box .intro--image img{
    border-radius: 50%;
}

.bio--content .bio--title{
    text-align: center;
    margin-bottom: 3rem;
}

.biography--content--box .intro--image{
    text-align: right;
    margin-bottom: 1rem;
}

